import React, {useEffect, useState} from "react";
import Report from "./Report";
import {Box, Button, Flex, FormControl, FormLabel, Heading, Select, Text, useColorModeValue} from "@chakra-ui/react";

export default function ReportList({reports, handleBan, handleUnban, handleDelete, handleCancelReport}) {
    const [sortBy, setSortBy] = useState('date');
    const [displayedReports, setDisplayedReports] = useState(10);
    const [totalReportsCount, setTotalReportsCount] = useState(0);

    useEffect(() => {
        setTotalReportsCount(reports.length);
    }, [reports]);

    const handleSortChange = (event) => {
        setSortBy(event.target.value);
    };

    const sortedReports = reports.sort((a, b) => {
        if (sortBy === 'date') {
            return new Date(b.createdAt) - new Date(a.createdAt);
        } else if (sortBy === 'username') {
            return a.postedBy.username.localeCompare(b.postedBy.username);
        } else if (sortBy === 'type') {
            return a.type.localeCompare(b.type);
        }
        return 0;
    });


    const displayedSortedReports = sortedReports.slice(0, displayedReports);

    const loadMoreReports = () => {
        if (displayedReports + 10 <= totalReportsCount) {
            setDisplayedReports(displayedReports + 10);
        } else {
            setDisplayedReports(totalReportsCount);
        }
    };

    return (
        <Box p={4}>
            <Flex justify="space-between" align="center" mb={4}>
                <Heading as="h1" size="xl">
                    Reports
                </Heading>
                <FormControl width="fit-content">
                    <FormLabel>Sort By</FormLabel>
                    <Select size="sm" value={sortBy} onChange={handleSortChange} >
                        <option value="date">Date</option>
                        <option value="username">Username</option>
                        <option value="type">Type</option>
                    </Select>
                </FormControl>
            </Flex>
            {displayedSortedReports.map((report, index) => (
                <Report id={report._id}
                        username={report.postedBy.username}
                        karma={report.postedBy.karmaPoints}
                        courseID={report.courseID}
                        text={report.text}
                        date={report.createdAt}
                        edited={report.edited}
                        postedBy={report.postedBy}
                        handleBan={handleBan}
                        handleUnban={handleUnban}
                        reports={report.report}
                        type={report.type}
                        handleDelete={handleDelete}
                        handleCancelReport={handleCancelReport}/>
            ))
            }
            <Text>Showing {displayedSortedReports.length} of {totalReportsCount} reports</Text>
            {totalReportsCount > displayedReports && <Button onClick={loadMoreReports} color="main.primary" mt={4}>
                Load More
            </Button>}
        </Box>
    )
}