import React, { useState } from "react";
import {
  Text,
  useToast,
  Tooltip,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  MenuGroup,
} from "@chakra-ui/react";
import { BsFillMegaphoneFill } from "react-icons/bs";
import request from "../api";
export default function ReportButton({ courseID, elementType, elementId }) {
  const toast = useToast();
  //   const [selectedReason, setSelectedReason] = useState(null);
  const handleReport = async (reason) => {
    try {

      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/${elementType}/${elementId}/report`,
        "POST",
        {
          elementId: elementId,
          elementType: elementType,
          reason: reason,
        }
      );
      toast({
        title:
          elementType.charAt(0).toUpperCase() +
          elementType.slice(1) +
          " reported",
        description:
          "Thanks for making this a better place! We will review your report as soon as possible.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        
        description: error.message,
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      console.error(error);
    }
  };

  return (
    <Menu>
      <Tooltip
        label={
          "Report " + elementType.charAt(0).toUpperCase() + elementType.slice(1)
        }
      >
        <MenuButton
          as={IconButton}
          aria-label={"Report " + elementType}
          icon={<BsFillMegaphoneFill />}
          size="xs"
          variant="ghost"
          color="gray.500"
          _hover={{ color: "red.500" }}
        />
      </Tooltip>

      <MenuList zIndex={3}>
        <MenuGroup
          ml={1}
          title={
            "Report " +
            elementType.charAt(0).toUpperCase() +
            elementType.slice(1) +
            " for:"
          }
        >
          <MenuItem>
            <Tooltip
              openDelay={500}
              label="The content insult, harass, or discriminate against other users."
            >
              <span>
                <Text onClick={() => handleReport("offensiveBehavior")}>
                  Offensive Behavior
                </Text>
              </span>
            </Tooltip>
          </MenuItem>
          <MenuItem>
            <Tooltip
              openDelay={500}
              label="Content that is sexually explicit, obscene, violent, or otherwise deemed inappropriate."
            >
              <span>
                <Text onClick={() => handleReport("inappropriateContent")}>
                  Inappropriate Content
                </Text>
              </span>
            </Tooltip>
          </MenuItem>
          <MenuItem>
            <Tooltip
              openDelay={500}
              label="Content that is advertising, irrelevant or repetitive."
            >
              <span>
                <Text onClick={() => handleReport("spam")}>Spam</Text>
              </span>
            </Tooltip>
          </MenuItem>
          <MenuItem>
            <Tooltip
              openDelay={500}
              label="Content that is factually incorrect or misleading."
            >
              <span>
                <Text onClick={() => handleReport("misinformation")}>
                  Misinformation
                </Text>
              </span>
            </Tooltip>
          </MenuItem>
          <MenuItem>
            <Tooltip
              openDelay={500}
              label="Content that contains personal information about other users."
            >
              <span>
                <Text onClick={() => handleReport("personalInformation")}>
                  Personal Information
                </Text>
              </span>
            </Tooltip>
          </MenuItem>
          <MenuItem>
            <Tooltip
              openDelay={500}
              label="Content that infringes on the rights of others."
            >
              <span>
                <Text onClick={() => handleReport("copyrightInfringement")}>
                  Copyright Infringement
                </Text>
              </span>
            </Tooltip>
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
