import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Avatar,
  HStack,
  Badge,
  Button,
  Textarea,
  Link,
} from "@chakra-ui/react";

import DateEditedHStack from "./DateEditedHStack";
import DeleteEditButtons from "./DeleteEditButtons";
import LikeDislikeButton from "./LikeDislikeButton";
import ReportButton from "./ReportButton";
import { editElement } from "./DeleteEditButtons";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import request from '../api';
export default function Answer({
  questionId,
  id,
  courseID,
  username,
  karma,
  avatarID,
  text,
  date,
  edited,
  authorDeleted,
  isAdmin,
  likes,
  dislikes,
  isLikedByUser,
  isDislikedByUser,
  isAuthor,
  fetchQuestions,
  updateUser,
  setUpdateUser,
  translation,
}) {
  const fetchedText = text;
  const toast = useToast();
  const [isEditingAnswer, setIsEditingAnswer] = useState(false);
  const [editedAnswer, setEditedAnswer] = useState(text);
  const { t, i18n } = useTranslation();
  const [showTranslation, setShowTranslation] = useState(i18n.language === "en");

  const toggleTranslation = () => {
    setShowTranslation(!showTranslation);
  };
  const handleAnswerEdit = async () => {
    text = editedAnswer;
    if (text.trim() === fetchedText.trim()) return;

    if (text.trim() === "") {
      toast({
        title: t("empty_answer_title"),
        description: t("empty_answer_description"),
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      setEditedAnswer(fetchedText);
      setIsEditingAnswer(false);
      return;
    }

    const success = await editElement(
      editedAnswer,
      `${courseID}/questions/${questionId}/answers/${id}`,
      "answer",
      toast
    );

    if (!success) setEditedAnswer(fetchedText);
    fetchQuestions();
    setIsEditingAnswer(false);
    if (success) {
      try {
        await request(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/translate`,
          "POST",
          {
            text: editedAnswer.trim(),
            elementID: id,
            elementType: "answer",
          }
        );
      } catch (error) {console.error("Translation failed: " ,error)}
    }
  };

  return (
    <Flex alignItems="center" mr={1}>
      <Avatar
        color="gray.900"
        name={authorDeleted ? null : username}
        src={
          !authorDeleted
            ? `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/user/avatar/${avatarID}`
            : null
        }
        size="md"
        mr={2}
      />
      <Box flex="1">
        <HStack spacing={2}>
          <Flex>
            <Text fontWeight={!authorDeleted && "bold"}>
              {authorDeleted ? "deleted" : username}
            </Text>
            {isAuthor && !authorDeleted && <Text ml={1}>{"("+ t("you") + ")"} </Text>}
          </Flex>
          {!authorDeleted && <Badge colorScheme="light-grey">🔥 {karma}</Badge>}
          {isAuthor || isAdmin ? (
            <DeleteEditButtons
              typeName={"answer"}
              isEditing={isEditingAnswer}
              setIsEditing={setIsEditingAnswer}
              route={`${courseID}/questions/${questionId}/answers/${id}`}
              fetchData={fetchQuestions}
              isAuthor={isAuthor}
              updateUser={updateUser}
              setUpdateUser={setUpdateUser}
            />
          ) : (
            <ReportButton
              courseID={courseID}
              elementType={"answer"}
              elementId={id}
            />
          )}
        </HStack>
        <DateEditedHStack date={date} edited={edited} />

        <Box flex={1}>
          {isEditingAnswer ? (
            <Textarea
              value={editedAnswer}
              onChange={(e) => setEditedAnswer(e.target.value)}
              mb={2}
              resize="none"
            />
          ) : (
            <Text
              as={
                showTranslation && translation.language === i18n.language
                  ? "i"
                  : null
              }
            >
              {showTranslation && translation.language === i18n.language
                ? translation.text
                : text}{" "}
              {translation && translation.language === i18n.language && (
                <Button
                  variant="link"
                  color="main.primary"
                  size="sm"
                  ml={2}
                  onClick={toggleTranslation}
                >
                  {showTranslation ? t("show_original") : t("translate")}
                </Button>
              )}
            </Text>
          )}

          <Box d="flex" justifyContent="flex-end" alignSelf={"right"}>
            {isEditingAnswer ? (
              <HStack spacing={2}>
                <Box flex="1" />
                <Button
                  colorScheme="gray"
                  size="sm"
                  onClick={() => {
                    setEditedAnswer(fetchedText);
                    setIsEditingAnswer(false);
                  }}
                  mr={2}
                >
                  {t("cancel")}
                </Button>

                <Button
                  color="white"
                  bg="main.primary"
                  _hover={{ bg: "main.secondary" }}
                  size="sm"
                  onClick={handleAnswerEdit}
                  justifyContent={"flex-end"}
                >
                  {t("save")}
                </Button>
              </HStack>
            ) : null}
          </Box>
        </Box>
      </Box>

      <LikeDislikeButton
        likes={likes}
        dislikes={dislikes}
        isLikedByUser={isLikedByUser}
        isDislikedByUser={isDislikedByUser}
        courseID={courseID}
        route={`${courseID}/questions/${questionId}/answers/${id}`}
        fetchData={fetchQuestions}
        updateUser={updateUser}
        setUpdateUser={setUpdateUser}
      />
    </Flex>
  );
}
