// CourseOverview.js
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // Updated import
import { useToast, useMediaQuery } from '@chakra-ui/react';

import CourseMobile from './CourseMobile.js';
import CourseDesktop from './CourseDesktop.js';
import AuthContext from '../AuthContext.js';
import request from '../api.js';
import './transitions.css';
import { useTranslation } from "react-i18next";
import { nameTranslation } from '../components/utils/Utils';

export default function CourseOverview({ updateUser, setUpdateUser }) {
  const { isSignedIn, role } = useContext(AuthContext);
  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [isEditingCourseName, setIsEditingCourseName] = useState(false);
  const [editedCourseName, setEditedCourseName] = useState('');
  const [selectedCommentValue, setSelectedCommentValue] = useState('top');
  const [hasRated, setHasRated] = useState(false);
  const [hasFaved, setHasFaved] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const [commentData, setCommentData] = useState(null);
  const [questionData, setQuestionData] = useState(null);
  const [courseFound, setCourseFound] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const { i18n } = useTranslation();

  const { courseID } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const allowedRoles = ['ADMIN', 'STUDENT', 'MODERATOR'];
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    fetchCourseData();
    fetchQuestionsAndComments();
  }, [selectedCommentValue, courseID, role, updateUser]);

  useEffect(() => {
    if (isSignedIn) {
      checkVerificationStatus();
    }
  }, [isSignedIn]);

  const fetchCourseData = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}`,
        'GET'
      );
      console.log('response', response);
      
      setEditedCourseName(nameTranslation(response.name, i18n.language));
      setCourseData(response);
      setHasRated(response.hasRated);
      setHasFaved(response.hasFaved);
      setCourseFound(true);
    } catch (error) {
      if (error.message === 'Course not found.') {
        navigate('/*');
      } else {
        console.error(error);
      }
    }
  };

  const fetchComments = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/comments/${selectedCommentValue}`,
        'GET'
      );
      setCommentData(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchQuestions = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/questions`,
        'GET'
      );
      setQuestionData(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchQuestionsAndComments = async () => {
    fetchQuestions();
    fetchComments();
  };

  const handleCourseNameChange = async () => {
    try {
      const response = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/updateCourseName`,
        'PUT',
        {
          name: editedCourseName,
          lng: i18n.language,
        }
      );
      toast({
        title: 'Course name updated',
        description: 'Course name has been updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchCourseData();
      setIsEditingCourseName(false);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Course name could not be updated',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCommentSelectChange = event => {
    setSelectedCommentValue(event.target.value.toString());
  };

  const handleSignIn = () => {
    const currentPath = window.location.pathname;
    navigate(`/sign-in?redirect=${encodeURIComponent(currentPath)}`);
  };

  async function checkVerificationStatus() {
    try {
      const data = await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/verification-status`,
        'GET',
        null
      );
      setEmailVerified(data.emailVerified);
    } catch (error) {
      console.error('Error checking verification status:', error);
      setEmailVerified(false);
    }
  }

  const favCourse = async (courseID) => {
    try {
      await request(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/courses/${courseID}/favorite`,
        'PUT'
      );
      setUpdateUser(!updateUser);
    } catch (error) {
      console.error('Error favoriting course:', error);
    }
  }

  const renderHelmet = () => {
    if (!courseData) return null;

    const courseName = courseData.name.en;
    const institutionName = courseData.institution.name.en;
    const institutionCode = courseData.institution.code;

    return (
      <Helmet>
        <title>{`${institutionCode} - ${courseName}`}</title>
        <meta
          name="description"
          content={`Join Classrate to explore and rate the course ${courseName} offered by ${institutionName}. Discover student experiences and more!`}
        />
        <meta
          name="keywords"
          content={`Classrate, course ratings, university courses, ${courseName}, ${institutionName}, ${institutionCode}`}
        />
        <meta property="og:title" content={`${institutionCode} - ${courseName}`} />
        <meta
          property="og:description"
          content={`Join Classrate to explore and rate the course ${courseName} offered by ${institutionName}. Discover student experiences and more!`}
        />
      </Helmet>
    );
  };

  return courseFound ? (isLargerThan768 ? (
    <>
      {renderHelmet()}
      <CourseDesktop 
        courseData={courseData}
        questionData={questionData}
        commentData={commentData}
        selectedCommentValue={selectedCommentValue}
        setSelectedCommentValue={setSelectedCommentValue}
        isEditingDetails={isEditingDetails}
        setIsEditingDetails={setIsEditingDetails}
        isEditingCourseName={isEditingCourseName}
        setIsEditingCourseName={setIsEditingCourseName}
        editedCourseName={editedCourseName}
        setEditedCourseName={setEditedCourseName}
        isSignedIn={isSignedIn}
        allowedRoles={allowedRoles}
        role={role}
        handleSignIn={handleSignIn}
        emailVerified={emailVerified}
        hasRated={hasRated}
        hasFaved={hasFaved}
        setHasRated={setHasRated}
        fetchComments={fetchQuestionsAndComments}
        fetchCourseData={fetchCourseData}
        fetchQuestionsAndComments={fetchQuestionsAndComments}
        handleCourseNameChange={handleCourseNameChange}
        handleCommentSelectChange={handleCommentSelectChange}
        favCourse={favCourse}
        updateUser={updateUser}
        setUpdateUser={setUpdateUser}
        navigate={navigate}
      />
    </>
  ) : (
    <>
      {renderHelmet()}
      <CourseMobile
        courseData={courseData}
        questionData={questionData}
        commentData={commentData}
        selectedCommentValue={selectedCommentValue}
        setSelectedCommentValue={setSelectedCommentValue}
        isEditingDetails={isEditingDetails}
        setIsEditingDetails={setIsEditingDetails}
        isEditingCourseName={isEditingCourseName}
        setIsEditingCourseName={setIsEditingCourseName}
        editedCourseName={editedCourseName}
        setEditedCourseName={setEditedCourseName}
        isSignedIn={isSignedIn}
        allowedRoles={allowedRoles}
        role={role}
        handleSignIn={handleSignIn}
        emailVerified={emailVerified}
        hasRated={hasRated}
        hasFaved={hasFaved}
        setHasRated={setHasRated}
        fetchComments={fetchQuestionsAndComments}
        fetchCourseData={fetchCourseData}
        fetchQuestionsAndComments={fetchQuestionsAndComments}
        handleCourseNameChange={handleCourseNameChange}
        handleCommentSelectChange={handleCommentSelectChange}
        favCourse={favCourse}
        updateUser={updateUser}
        setUpdateUser={setUpdateUser}
        navigate={navigate}
      />
    </>
  )) : null;
}
