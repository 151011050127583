import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  Tooltip,
  useMediaQuery,
  useToast,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

import AuthContext from "../AuthContext";
import request from "../api";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function SignInComponent() {
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { colorMode, toggleColorMode } = useColorMode();
  const allowedRoles = ["ADMIN", "STUDENT", "MODERATOR"];
  const { t, i18n } = useTranslation();
  const { handleSignIn, isSignedIn, role } = useContext(AuthContext);

  const urlParams = new URLSearchParams(window.location.search);

  const toast = useToast();

  useEffect(() => {
    if (isSignedIn && allowedRoles.includes(role)) {
      navigate("/");
    }
  }, []);

  const navigate = useNavigate();
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await request(`${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/login`,
        "POST",
        {
          username: usernameOrEmail,
          password: password,
        }
      );

      const { access_token, expires_in, role, userColorMode, language } = data;
      handleSignIn(access_token, expires_in, role);

      const redirectPath = urlParams.get("redirect");

      if (colorMode !== userColorMode) {
        if (userColorMode === "system") {
          localStorage.removeItem("colorMode");
        } else {
          toggleColorMode();
        }
      }

      if (language !==  null && language !== i18n.language) {
        i18n.changeLanguage(language);
      }

      if (redirectPath) {
        navigate(redirectPath);
      } else {
        navigate("/");
      }
    } catch (error) {
      if (
        error.message == "Internal server error" ||
        error.message == "Failed to fetch"
      ) {
        toast({
          title: "Damn, something went wrong...",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        setErrors(error.message);
      }
    }
  };

  return (
    <Flex
      justify="center"
      align="center"
      height={isLargerThan768 ? "100vh" : null}
    >
      <Stack
        bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
        rounded="xl"
        p={4}
        direction="column"
        shadow="lg"
        justify="space-between"
        height={isLargerThan768 ? "420px" : null}
        spacing={2}
        w={"100%"}
      >
        <Heading lineHeight={1.1} fontSize="4xl">
          {t("sign_in")}
        </Heading>

        <Box
          as="form"
          w={isLargerThan768 ? "400px" : null}
          h={isLargerThan768 ? "200px" : null}
          justifyContent="space-evenly"
          spacing={2}
        >
          <Stack spacing={2}>
            <Box display="flex" justify="center" align="center">
              <FormControl>
                <FormLabel htmlFor="usernameOrEmail">
                  {t("email_or_username")}
                </FormLabel>
                <Input
                  placeholder={t("your_email_or_username")}
                  value={usernameOrEmail}
                  onChange={(e) => setUsernameOrEmail(e.target.value)}
                  borderColor="gray.800"
                  _placeholder={{ color: "gray.500" }}
                  id="usernameOrEmail"
                  width="100%"
                />
              </FormControl>
            </Box>

            <Box display="flex" justify="center" align="center">
              <FormControl>
                <FormLabel htmlFor="password">{t("password")}</FormLabel>
                <Input
                  type="password"
                  placeholder={t("your_password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  borderColor="gray.800"
                  _placeholder={{ color: "gray.500" }}
                  onKeyDown={handleKeyDown}
                  id="password"
                  width="100%"
                />
              </FormControl>
            </Box>

            {errors && (
              <Text color="red.500" fontSize="sm" textAlign="center">
                {errors}
              </Text>
            )}

            <Button
              fontFamily="heading"
              onClick={handleSubmit}
              disabled={!usernameOrEmail || !password}
              bg="main.primary"
              _hover={{ bg: "main.secondary" }}
              color="white"
              width="100%"
              mt={3}
            >
              {t("sign_in_button")}
            </Button>
          </Stack>
        </Box>
        <Box>
          <Stack
            direction="row"
            justify="center"
            align="center"
            spacing={1}
            mt={1}
          >
            <Text fontSize="sm">{t("not_registered_yet")}</Text>
            <Link fontSize="sm" href="/sign-up" fontWeight="bold">
              {t("sign_up")}
            </Link>
          </Stack>

          <Stack direction="row" justify="center" align="center" spacing={1}>
            <Text fontSize="sm">{t("forgot_password")}</Text>
            <Link fontSize="sm" href="/forgot-password" fontWeight="bold">
              {t("reset_password")}
            </Link>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
