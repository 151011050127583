import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Code,
  Flex,
  HStack,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { DeleteIcon, LockIcon, StarIcon, UnlockIcon } from '@chakra-ui/icons';
import DateEditedHStack from './DateEditedHStack';
import React from 'react';
import UserAvatar from './UserAvatar';
import { useNavigate } from 'react-router-dom';
function UnLockIcon() {
  return null;
}

export default function Report({
  id,
  karma,
  text,
  date,
  edited,
  courseID,
  postedBy,
  reports,
  handleBan,
  handleCancelReport,
  handleUnban,
  handleDelete,
  type,
}) {
  let typeColor;
  const navigate = useNavigate();
  switch (type) {
    case 'QUESTION':
      typeColor = 'blue';
      break;
    case 'ANSWER':
      typeColor = 'green';
      break;
    case 'COMMENT':
      typeColor = 'orange';
      break;
    default:
      typeColor = 'gray';
      break;
  }

  return (
    <Flex
      alignItems="center"
      mb={2}
      bg="white"
      color="gray.900"
      borderRadius={4}
      shadow="md"
      p={2}
    >
      <UserAvatar user={postedBy} size={'md'} />
      <Box flex="1" ml={5}>
        <Flex alignItems="center">
          <Text fontWeight="bold">{postedBy.username}</Text>
          <Badge colorScheme="light-grey" ml={2}>
            🔥 {karma}
          </Badge>
          <Code
            colorScheme="blue"
            fontSize="sm"
            _hover={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(`/course/${courseID}`);
            }}
            mr={2}
          >
            {courseID}
          </Code>
          <Box flex="1" />
        </Flex>
        <Flex alignItems="center">
          {postedBy.banned ? (
            <Badge colorScheme="red" fontSize="sm" mr={2}>
              Banned
            </Badge>
          ) : (
            <DateEditedHStack date={date} edited={edited} />
          )}
        </Flex>
        <Text>{text}</Text>
        <Flex alignItems="center">
          <Code colorScheme={typeColor} fontSize="sm">
            {type}
          </Code>
          {reports.offensiveBehavior.length > 0 && (
            <Code colorScheme="red" ml={2}>
              Offensive behaviour
            </Code>
          )}
          {reports.inappropriateContent.length > 0 && (
            <Code colorScheme="red" ml={2}>
              Inappropriate content
            </Code>
          )}
          {reports.spam.length > 0 && (
            <Code colorScheme="red" ml={2}>
              Spam
            </Code>
          )}
          {reports.personalInformation.length > 0 && (
            <Code colorScheme="red" ml={2}>
              Personal information
            </Code>
          )}
          {reports.misinformation.length > 0 && (
            <Code colorScheme="red" ml={2}>
              Misinformation
            </Code>
          )}
          {reports.copyrightInfringement.length > 0 && (
            <Code colorScheme="red" ml={2}>
              Copyright infringement
            </Code>
          )}
          <Box flex="1" />
          <ButtonGroup size="sm">
            <Button
              colorScheme="green"
              onClick={() => handleCancelReport(id, type)}
              mr={2}
            >
              Cancel report
            </Button>
            {!postedBy.banned && (
              <Button
                leftIcon={<LockIcon />}
                colorScheme="red"
                onClick={() => handleBan(postedBy._id)}
                mr={2}
              >
                Ban User
              </Button>
            )}
            {postedBy.banned && (
              <Button
                leftIcon={<UnlockIcon />}
                colorScheme="green"
                onClick={() => handleUnban(postedBy._id)}
                mr={2}
              >
                Unban User
              </Button>
            )}
          </ButtonGroup>
          <IconButton
            icon={<DeleteIcon />}
            colorScheme="red"
            aria-label="Delete Comment"
            onClick={() => handleDelete(id, type, courseID)}
            size="sm"
          />
        </Flex>
      </Box>
    </Flex>
  );
}
