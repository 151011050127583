import { Skeleton, Tag, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { nameTranslation } from './utils/Utils';
function CourseTag({ course, isLoading }) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();


  return isLoading ? (
    <Tag
      size="lg"
      variant="solid"
      colorScheme="blue"
      cursor="pointer"
      justifyContent="center"
      alignItems="center"
      display="flex"
      bg={useColorModeValue("main.elementBgLight", "main.elementBgDark")}
      borderRadius={70}
      px={4}
      py={2}
      color="black"
      _hover={{ background: "main.secondary" }}
    >
      <Skeleton height="20px" width="100px" />
    </Tag>
  ) : (
    <Tag
      size="lg"
      variant="solid"
      cursor="pointer"
      justifyContent="center"
      alignItems="center"
      display="flex"
      bg={useColorModeValue(null, "main.elementBgDark")}
      borderRadius={70}
      px={4}
      py={2}
      _hover={{ background: "main.secondary" }}
      onClick={() => navigate(`/course/${course.id}`)}
    >
      {nameTranslation(course.name, i18n.language)}
    </Tag>
  );
}

export default CourseTag;
